import React from 'react'

import SEO from '../../components/seo'

import LayoutValueBasedModels from '../../pageLayouts/LayoutProducts/LayoutValueBasedModels'

const ValueBaseModelsPage = () => (
  <div>
    <SEO
      title="Data Analytics and Decision Support Platform for Market Access"
      keywords={[
        'pulse digital',
        'healthcare',
        'react',
        'software engineering',
        'new york',
        'nyc',
        'pulse',
        'javascript',
        'analytics',
        'development',
        'pathways',
        'alternative payment models',
        'value based care',
        'quality programs',
        'tracking',
      ]}
    />
    <LayoutValueBasedModels />
  </div>
)

export default ValueBaseModelsPage
