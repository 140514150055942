import React from 'react'

import ProductHero from '../../../../components/ProductHero'

import toolContent from '../../../../content/toolContent'
import assets from '../../../../content/assets'

const ValueBasedModelsHero = () => (
  <div>
    <ProductHero
      backgroundImage={assets.images.fluidBubblesPrimary4}
      image={toolContent.heroToolImage.valueBasedModels}
      imageAltText="Value-Based Models Tool Image"
      icon={toolContent.iconWhite.valueBasedModels}
      title={toolContent.title.valueBasedModels}
      description={toolContent.description.valueBasedModels}
    />
  </div>
)

export default ValueBasedModelsHero
