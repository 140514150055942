import React from 'react'

import { Flex } from '@rebass/grid/emotion'
import { GridContainer } from '../../../../components/Grid'
import { ValueListContainer, ValueListItem } from '../../../../components/ValueList'
import QuestionBlock from '../../../../components/QuestionBlock'
import {
  SectionDashboardDescription,
  SectionDashboardQuestions,
  SectionDashboardContainer,
  SectionDashboardContentContainer,
} from '../../../../components/SectionDashboard'

import { Colors } from '../../../../utils/styles'

const valueAccountsColor = Colors.SECONDARY
const valueDashboardName = 'Account Profiles'
const valueDashboardDescription = `
  In-depth account profiles of value-based models have been developed to guide
  targeted engagement action planning, based on operational models, provider
  workflow integration, and key influencers.
`

const valueAccountsValues = (
  <ValueListContainer>
    <ValueListItem
      iconColor={valueAccountsColor}
      text="Understand value-based models and their impact on product utilization and sequencing"
    />
    <ValueListItem
      iconColor={valueAccountsColor}
      // eslint-disable-next-line
      text="Identify key models, decision-makers & influence opportunities for targeted engagement planning"
    />
    <ValueListItem
      iconColor={valueAccountsColor}
      text="Maintain updates on emerging participants, brand positioning, and key influencers"
    />
  </ValueListContainer>
)

const valueAccountQuestions = (
  <Flex flexWrap="wrap">
    <QuestionBlock
      title="Program Overview"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371859/pulse-analytics-marketing/icons/pathways-accounts-program-overview.svg"
      question="What is the value-based model’s business model & strategic focus? "
      color={valueAccountsColor}
    />
    <QuestionBlock
      title="Adoption & Participants"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371859/pulse-analytics-marketing/icons/pathways-accounts-adoption-participants.svg"
      question="What is the organization’s adoption & footprint among providers and payers?"
      color={valueAccountsColor}
    />
    <QuestionBlock
      title="Development Process"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371859/pulse-analytics-marketing/icons/pathways-accounts-development-process.svg"
      question="What is the development process, meeting times and data submission opportunities?"
      color={valueAccountsColor}
    />
    <QuestionBlock
      title="Capabilities & Adherence"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371859/pulse-analytics-marketing/icons/pathways-accounts-capabilities-adherence.svg"
      question="How are models integrating within HCP workflows & what are avg. adherence rates?"
      color={valueAccountsColor}
    />
    <QuestionBlock
      title="Decision-Markers & Influencers"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371859/pulse-analytics-marketing/icons/pathways-accounts-influencers.svg"
      question="Which steering committee members  & influencers should be prioritized?"
      color={valueAccountsColor}
    />
  </Flex>
)

const ValueBasedModelsAccounts = () => (
  <SectionDashboardContainer>
    <SectionDashboardContentContainer color={valueAccountsColor}>
      <GridContainer flexWrap="wrap" style={{ padding: 0 }}>
        <SectionDashboardDescription
          title={valueDashboardName}
          description={valueDashboardDescription}
          values={valueAccountsValues}
        />
        <SectionDashboardQuestions
          color={valueAccountsColor}
          questions={valueAccountQuestions}
          subtitle={valueDashboardName}
        />
      </GridContainer>
    </SectionDashboardContentContainer>
  </SectionDashboardContainer>
)

export default ValueBasedModelsAccounts
