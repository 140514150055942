import React from 'react'

import Layout from '../../../components/Layout'
import RequestDemoCtaBlock from '../../../components/RequestDemoCtaBlock'

import ValueBasedModelsHero from './ValueBasedModelsHero'
import ValueBasedModelsManagement from './ValueBasedModelsManagement'
import ValueBasedModelsAccounts from './ValueBasedModelsAccounts'

const LayoutValueBasedModels = () => (
  <Layout>
    <ValueBasedModelsHero />
    <ValueBasedModelsManagement />
    <ValueBasedModelsAccounts />
    <RequestDemoCtaBlock />
  </Layout>
)

export default LayoutValueBasedModels
