import React from 'react'

import { Flex } from '@rebass/grid/emotion'
import { GridContainer } from '../../../../components/Grid'
import { ValueListContainer, ValueListItem } from '../../../../components/ValueList'
import QuestionBlock from '../../../../components/QuestionBlock'
import {
  SectionDashboardDescription,
  SectionDashboardQuestions,
  SectionDashboardContainer,
  SectionDashboardContentContainer,
} from '../../../../components/SectionDashboard'

import { Colors } from '../../../../utils/styles'

const valueManagementColor = Colors.PRIMARY
const valueDashboardName = 'Management Dashboard'
const valueDashboardDescription = `
  The Pathways and APM Tool provides a Management view to show high-level
  pathways trends, including utilization dynamics, brand positioning &
  development, and engagement opportunities.
`

const valueManagementValues = (
  <ValueListContainer>
    <ValueListItem iconColor={valueManagementColor} text="Monitor and track landscape trends" />
    <ValueListItem
      iconColor={valueManagementColor}
      text="Identify gaps and opportunities across portfolio"
    />
    <ValueListItem
      iconColor={valueManagementColor}
      text="Support account management and targeting"
    />
  </ValueListContainer>
)

const valueManagementQuestions = (
  <Flex flexWrap="wrap">
    <QuestionBlock
      title="Product Sequencing & Utilization"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371764/pulse-analytics-marketing/icons/pathways-mgmt-product-sequencing.svg"
      // eslint-disable-next-line
      question="How are our products sequenced on dominant value-based programs vs. the FDA approved indication and competitors?"
      color={valueManagementColor}
    />
    <QuestionBlock
      title="Provider Participation"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371763/pulse-analytics-marketing/icons/pathways-mgmt-provider-participation.svg"
      // eslint-disable-next-line
      question="To what extent are providers participating in value-based models & what is the potential impact on prescribing behaviors?"
      color={valueManagementColor}
    />
    <QuestionBlock
      title="Payer Adoption"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371763/pulse-analytics-marketing/icons/pathways-mgmt-payer-adoption.svg"
      // eslint-disable-next-line
      question="Which priority payers are sponsoring emerging value-based models and how has payer sponsorship evolved over time?"
      color={valueManagementColor}
    />
    <QuestionBlock
      title="Regional Targeting"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371763/pulse-analytics-marketing/icons/pathways-mgmt-regional-targeting.svg"
      // eslint-disable-next-line
      question="Which states and regions have greater exposure (providers and payers) to value based programs?"
      color={valueManagementColor}
    />
    <QuestionBlock
      title="Steering Committee Influencers"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565371763/pulse-analytics-marketing/icons/pathways-mgmt-influencers.svg"
      // eslint-disable-next-line
      question="Who are the priority stakeholders that influence model development and implementation for targeting? "
      color={valueManagementColor}
    />
  </Flex>
)

const ValueBasedModelsManagement = () => (
  <SectionDashboardContainer>
    <SectionDashboardContentContainer color={valueManagementColor}>
      <GridContainer flexWrap="wrap" style={{ padding: 0 }}>
        <SectionDashboardDescription
          title={valueDashboardName}
          description={valueDashboardDescription}
          values={valueManagementValues}
        />
        <SectionDashboardQuestions
          color={valueManagementColor}
          questions={valueManagementQuestions}
          subtitle={valueDashboardName}
        />
      </GridContainer>
    </SectionDashboardContentContainer>
  </SectionDashboardContainer>
)

export default ValueBasedModelsManagement
